import React, { useState, useEffect, useContext } from 'react';
// import { Storage } from 'aws-amplify';
import { AudioContext, useAudioContext } from './AudioContext';


import './rodentStyle.css';

function RodentComponent({ setScore, level, speed }) {
    const [whack, setWhack] = useState(0);
    const [clearTimeoutTransEnd, setClearTimeoutTransEnd] = useState(0);
    const [buttonStyleTop, setButtonStyleTop] = useState('100%');

    const { splatAudio, giggleAudio } = useContext(AudioContext);

    // const transitionTime = level === 1 ? 1000 : level >= 3 ? 450 : 750;
    // const transitionTime = 1500;
    
    const speedLevel = {
        1: {
          1: 2000,
          2: 1750,
          3: 1500,
        },
        2: {
          1: 1750,
          2: 1500,
          3: 1250,
        },
        3: {
          1: 1500,
          2: 1250,
          3: 1000,
        },
        4: {
          1: 1250,
          2: 1000,
          3: 750,
        },
        5: {
          1: 900,
          2: 650,
          3: 450,
        },
    };

    const transitionTime = speedLevel[speed][level];
    const startTransitionUp = Math.floor(Math.random() * (12000 - 1000 + 1)) + 1000;
    const startTransitionDown = startTransitionUp + transitionTime;

    function playSplatSound(){
        if (splatAudio) {
            splatAudio.play();
        } else {
        }
      };

    function playGiggleSound(){
        if(giggleAudio){
            giggleAudio.play();
        } else {
        }
    }
    
    useEffect(() => {

        // Set the button style to top: 20% after random number seconds
        const transitionUp = setTimeout(() => {
        setButtonStyleTop('20%');
        }, startTransitionUp);

        // Set the button style to top: 100% after transitionUp ends
        const transitionDown = setTimeout(() => {
            setButtonStyleTop('100%');
            
        }, startTransitionDown);

        // Set whack to 2 after the second transition completes
        const transitionEnd = setTimeout(() => {
            setWhack(2);
            setScore(2);
            playGiggleSound();

        }, transitionTime + startTransitionDown);

        if (clearTimeoutTransEnd === 1) {
            clearTimeout(transitionEnd);
        }

        // Clear the timeouts when the component unmounts
        return () => {
        clearTimeout(transitionUp);
        clearTimeout(transitionDown);
        clearTimeout(transitionEnd);
        };
    }, [clearTimeoutTransEnd]); 

  
    const handleClick = () => {
        setScore(1);
        setWhack(1);
        setClearTimeoutTransEnd(1);
        playSplatSound();
    };

    return (
        <div>
            {whack === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        className='rodent'
                        onClick={handleClick}
                        style={{
                        top: buttonStyleTop,
                        transition: `top ${transitionTime / 1000}s ease-in-out`, // Use the variable
                        }}
                    ></button>
                </div>
            
            )}
            {whack === 1 && <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div
                            style={{
                            width: '70%',
                            height: '10%',
                            backgroundColor: 'white',
                            position: 'absolute',
                            transform: 'rotate(-45deg)', // Center horizontally

                            }}
                        ></div>
                        <div
                            style={{
                            width: '70%',
                            height: '10%',
                            backgroundColor: 'white',
                            position: 'absolute',
                            transform: 'rotate(45deg)', // Center horizontally
                        }}
                        ></div>
                    </div>

                            
                </div>}
            {whack === 2 && <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div
                            style={{
                            width: '70%',
                            height: '70%',
                            backgroundColor: 'brown',
                            position: 'absolute',
                            borderRadius: '50%',
                            }}
                        ></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default RodentComponent;

