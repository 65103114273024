// AudioContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Storage } from 'aws-amplify';

export const AudioContext = createContext();

export const useAudioContext = () => {
  return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
  const [splatAudio, setSplatAudio] = useState(null);
  const [giggleAudio, setGiggleAudio] = useState(null);
  const [startAudio, setStartAudio] = useState(null);
  const [playAudio, setPlayAudio] = useState(null);
  const [shortGiggleAudio, setShortGiggleAudio] = useState(null);
  const [laughAudio, setLaughAudio] = useState(null);
  const [yahooAudio, setYahooAudio] = useState(null);
  const [fanfareAudio, setFanfareAudio] = useState(null);

  const [isMute, setIsMute] = useState(true);

  useEffect(() => {
    const splatAudioKey = 'audio/147017__justinvoke__splat.wav';
    const giggleAudioKey = 'audio/343888__reitanna__ahoh.wav';
    const startAudioKey = 'audio/442911__scicodedev__calm_happy_rpgtownbackground.mp3';
    const playAudioKey = 'audio/417559__stivarts__north.mp3';
    const shortGiggleAudioKey = 'audio/323704__reitanna__cutie-laugh.wav';
    const laughAudioKey = 'audio/343984__reitanna__giggle10.wav';
    const fanfareAudioKey = 'audio/580310__colorscrimsontears__fanfare-2-rpg.wav';
    const yahooAudioKey = 'audio/471973__juanfg__yajuu.wav';

    const createAudioObject = async (audioKey, setAudio) => {
      try {
        const audioUrl = await Storage.get(audioKey);
        const audio = new Audio(audioUrl);

        if (audioKey === fanfareAudio){

        }
        

        setAudio(audio);
      } catch (error) {
        console.error('Error creating Audio object:', error);
      }
    };

    createAudioObject(splatAudioKey, setSplatAudio);
    createAudioObject(giggleAudioKey, setGiggleAudio);
    createAudioObject(startAudioKey, setStartAudio);
    createAudioObject(playAudioKey, setPlayAudio);
    createAudioObject(shortGiggleAudioKey, setShortGiggleAudio);
    createAudioObject(laughAudioKey, setLaughAudio);
    createAudioObject(fanfareAudioKey, setFanfareAudio);
    createAudioObject(yahooAudioKey, setYahooAudio);

    return () => {
      if (splatAudio) splatAudio.pause();
      if (giggleAudio) giggleAudio.pause();
      if (startAudio) startAudio.pause();
      if (playAudio) playAudio.pause();
      if (shortGiggleAudio) shortGiggleAudio.pause();
      if (laughAudio) laughAudio.pause();
      if (fanfareAudio) fanfareAudio.pause();
      if (yahooAudio) yahooAudio.pause();

    };
  }, []);

  const toggleMute = () => {
    if (isMute) {
      if (splatAudio) splatAudio.muted = true;
      if (giggleAudio) giggleAudio.muted = true;
      if (startAudio) startAudio.muted = true;
      if (playAudio) playAudio.muted = true;
      if (shortGiggleAudio) shortGiggleAudio.muted = true;
      if (laughAudio) laughAudio.muted = true;
      if (fanfareAudio) fanfareAudio.muted = true;
      if (yahooAudio) yahooAudio.muted = true;


    } else {
      if (splatAudio) splatAudio.muted = false;
      if (giggleAudio) giggleAudio.muted = false;
      if (startAudio) startAudio.muted = false;
      if (playAudio) playAudio.muted = false;
      if (shortGiggleAudio) shortGiggleAudio.muted = false;
      if (laughAudio) laughAudio.muted = false;
      if (fanfareAudio) fanfareAudio.muted = false;
      if (yahooAudio) yahooAudio.muted = false;

    }
    setIsMute(!isMute);
  };

  return (
    <AudioContext.Provider value={{ splatAudio, giggleAudio, playAudio, startAudio, shortGiggleAudio, laughAudio,fanfareAudio, yahooAudio, isMute, toggleMute }}>
      {children}
    </AudioContext.Provider>
  );
};
