import React, { useState } from 'react';
import './sliderStyle.css';

const SliderComponent = ({ speed, setSpeed, label, setLabel }) => {
    const labelsArray = ['Boomer','GenX','Millennial','GenZ','GenAlpha'];

  const handleChange = (event) => {
    setSpeed(event.target.value);
    setLabel(labelsArray[event.target.value - 1])
    console.log('set speed to ', event.target.value);
  };

  return (
    <div>
      <p><b>Speed: {label}</b></p>
      <input
        type="range"
        min="1"
        max="5"
        step="1"
        value={speed}
        onChange={handleChange}
        className='slider'
      />
    </div>
  );
};

export default SliderComponent;
