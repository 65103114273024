import React, { useState, useEffect, useContext } from 'react';
import './gameStyle.css';
import RodentComponent from './RodentComponent';
import { useAudioContext } from './AudioContext';
import SliderComponent from './SliderComponent';


function GameComponent() {
    const [score1, setScore1] = useState(0);
    const [score2, setScore2] = useState(0);
    const [score3, setScore3] = useState(0);
    const [score4, setScore4] = useState(0);
    const [score5, setScore5] = useState(0);
    const [score6, setScore6] = useState(0);
    const [score7, setScore7] = useState(0);
    const [score8, setScore8] = useState(0);
    const [score9, setScore9] = useState(0);
    const [score10, setScore10] = useState(0);
    const [score11, setScore11] = useState(0);
    const [score12, setScore12] = useState(0);
    const [score13, setScore13] = useState(0);
    const [score14, setScore14] = useState(0);
    const [score15, setScore15] = useState(0);
    const [score16, setScore16] = useState(0);

    const [subLevel, setSubLevel] = useState([0,0,0]);
    const [level, setLevel] = useState(1);

    const [rodentScore, setRodentScore] = useState(0);
    const [youScore, setYouScore] = useState(0);

    const [gameState, setGameState] = useState('start');
    const [gameWon, setGameWon] = useState(false);
    const [gridSize, setGridSize] = useState(3);
    const [speed, setSpeed] = useState(3);
    const [label, setLabel] = useState('Millenial');
    
    const { isMute, toggleMute } = useAudioContext();

    const { startAudio, playAudio, shortGiggleAudio, giggleAudio, laughAudio, fanfareAudio, yahooAudio } = useAudioContext();


    const handleStartClick = () => {
        setGameState('playing');
        playAudio.play();
        startAudio.pause();
    }

    const handleResetClick = () => {
        // reload page
        window.location.reload();
    }

    const updateSubLevel = () => {
        const scoreDifference = youScore - rodentScore;

        if ( scoreDifference < 1) {
            setSubLevel([0,0,0]);
        }
        if (scoreDifference === 1) {
            setSubLevel([1,0,0]);
        }
        if (scoreDifference === 2) {
            setSubLevel([1,1,0]);
        }
        if (scoreDifference === 3) {
            setSubLevel([1,1,1]);
            setGridSize(4);
        } else {
            setGridSize(3);
        }
        if (scoreDifference === 4) {
            setSubLevel([0,0,0]);
            setLevel(level + 1);
            setYouScore(0);
            setRodentScore(0);
        } 
    }

    useEffect(() => {

        if (gameState === 'youwin' || gameState === 'rodentwin') {
            // check level
            updateSubLevel();
            playAudio.pause()
            // Set a timeout to change gameState to 'start' after 4 seconds
            const timeoutId = setTimeout(() => {
                setGameState('start');
                startAudio.play();
            }, 4000);

            if (gameState === 'playing'){
            }

            if (gameState === 'youwin'){
                
                fanfareAudio.addEventListener('ended', ()=>{
                    yahooAudio.play();
                })

                fanfareAudio.play();
            }

            if (gameState === 'rodentwin'){

                shortGiggleAudio.addEventListener('ended', ()=>{
                    laughAudio.play();
                })
                laughAudio.addEventListener('ended', ()=>{
                    giggleAudio.play();
                })
                shortGiggleAudio.play();                
            }
    
            //   Clear the timeout when the component unmounts or when gameState changes
            return () => {
                clearTimeout(timeoutId);
            };
        }
      }, [gameState]);
    

    useEffect(() => {
        // Check for player win and return true or false
        function checkForWin(player) {
          // console.log(score1, score2, score3, score4, score5, score6, score7, score8, score9);
            if (gridSize === 3){
                switch (true) {
                    case score1 === player && score2 === player && score3 === player:
                        return true;
                    case score4 === player && score5 === player && score6 === player:
                        return true;
                    case score7 === player && score8 === player && score9 === player:
                        return true;
                    case score1 === player && score4 === player && score7 === player:
                        return true;
                    case score2 === player && score5 === player && score8 === player:
                        return true;
                    case score3 === player && score6 === player && score9 === player:
                        return true;
                    case score1 === player && score5 === player && score9 === player:
                        return true;
                    case score3 === player && score5 === player && score7 === player:
                    return true;
                    default:
                    return false;
                }  
            }

            if (gridSize === 4){
                switch (true) {
                    //horizontal wins
                    case score1 === player && score2 === player && score3 === player && score10 === player:
                        return true;
                    case score4 === player && score5 === player && score6 === player && score11 === player:
                        return true;
                    case score7 === player && score8 === player && score9 === player && score12 === player:
                        return true;
                    case score13 === player && score14 === player && score15 === player && score16 === player:
                        return true;
                    //vertical wins
                    case score1 === player && score4 === player && score7 === player && score13 === player:
                        return true;
                    case score2 === player && score5 === player && score8 === player && score14 === player:
                        return true;
                    case score3 === player && score6 === player && score9 === player && score15 === player:
                        return true;
                    case score10 === player && score11 === player && score12 === player && score16 === player:
                        return true;
                    //diagonal wins
                    case score1 === player && score5 === player && score9 === player && score16 === player:
                        return true;
                    case score10 === player && score6 === player && score8 === player && score13 === player:
                    return true;
                    
                    default:
                    return false;
                }  

            }
        
        }
        function checkForCat(){
            if(gridSize === 4) return [score1, score2, score3, score4, score5, score6, score7, score8, score9, score10, score11, score12, score13, score14, score15, score16].every(num=>num !== 0) && !youWin && !rodentWin;

            if(gridSize === 3) return [score1, score2, score3, score4, score5, score6, score7, score8, score9].every(num=>num !== 0) && !youWin && !rodentWin;
        }
        function clearScores(){
            const scoreSetters = [setScore1, setScore2, setScore3, setScore4, setScore5, setScore6, setScore7, setScore8, setScore9, setScore10, setScore11, setScore12, setScore13, setScore14, setScore15, setScore16];

            scoreSetters.forEach(setter => {
            setter(0);
            });

            setGameWon(false);
        }
    
        // Call the checkForWin function whenever any of the scores change
        const youWin = checkForWin(1);
        const rodentWin = checkForWin(2);
        const catWin = checkForCat();
        console.log('youWin ', youWin, 'rodentWin ', rodentWin, 'catWin ', catWin, 'gameWon ', gameWon);
        console.log(score1, score2, score3, score4, score5, score6, score7, score8, score9, score10, score11, score12, score13, score14, score15, score16);
        // Update score and change gameState
        if (level === 3 && subLevel[2] === 1 && youWin ){
            console.log('level 4 all games won');
            setGameState('allgameswon');
            setGameWon(true);
        } else if (youWin && !gameWon && level <= 3) {
            console.log('youwin and not gamewon, level ', level, 'subLevel', subLevel);
            setYouScore(youScore + 1);
            setGameWon(true);
            setTimeout(() => {
                setGameState('youwin');
                clearScores();
              }, 1000); 
        }
        if (rodentWin && !gameWon) {
            console.log('rodent win and not game won');
            setRodentScore(rodentScore + 1);
            setGameWon(true);
            setTimeout(() => {
                setGameState('rodentwin');
                clearScores();
              }, 1000); 
        }
        if (catWin && !gameWon && !youWin && !rodentWin) {
            console.log('cat win and not game won');
            setTimeout(() => {
                setGameState('rodentwin');
                clearScores();
              }, 1000); 
        }
    
      }, [score1, score2, score3, score4, score5, score6, score7, score8, score9, score10, score11, score12, score13, score14, score15, score16]);

    return (
        <div>
            <div className='flex-container' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div><button className='game' onClick={toggleMute}>
                    {isMute ? 'Mute' : 'Unmute'}
                    </button>
                </div>
                <div><b>Tic Tac Wac</b></div>
                <div><button className='game' onClick={handleResetClick}>
                    Reset
                    </button>
                </div>
            </div>
            {gameState === 'start' && <div className='parent-container-start'>
                <div className='flex-container'>
                    <p>
                        The moles are at it again...<br />
                        They are making big holes all over your lawn!<br />
                        If the moles make 3 holes in a row, score 1 for the moles.<br />
                        If you whack 3 moles in a row, score 1 for you!
                    </p>
                </div>
                <div>    
                    <button className='game' onClick={handleStartClick}>Start</button>
                </div>

                <div className='scoreboard flex-container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>You: {youScore}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>Rodent: {rodentScore}</div>
                    </div>
                </div>
                <div className="level-container">
                    <h1>Level {level}</h1>
                    <div className="circle-container">
                        <div className="circle" style={{backgroundColor: subLevel[0] === 1 ? 'green' : 'white' }}></div>
                        <div className="circle" style={{backgroundColor: subLevel[1] === 1 ? 'green' : 'white' }}></div>
                        <div className="circle" style={{backgroundColor: subLevel[2] === 1 ? 'green' : 'white' }}></div>
                    </div>
                </div>
                <div className="level-container">
                    <div className="speed-container">
                        <SliderComponent speed = {speed} setSpeed = {setSpeed} label = {label} setLabel = {setLabel}/>
                    </div>
                </div>
            </div>
            }
            {gameState === 'playing' && <div className='parent-container'>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>You: {youScore}</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>Rodent: {rodentScore}</div>
                </div>
                <div className="flex-container">
                    <div className="flex-item">
                        <RodentComponent setScore={setScore1} level={level} speed={speed} />
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore2} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore3} level={level} speed={speed}/>
                    </div>
                    {gridSize===4 && <div className="flex-item">
                        <RodentComponent setScore={setScore10} level={level} speed={speed}/>
                    </div>}
                </div>
                <div className="flex-container">
                    <div className="flex-item">
                        <RodentComponent setScore={setScore4} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore5} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore6} level={level} speed={speed}/>
                    </div>
                    {gridSize===4 && <div className="flex-item">
                        <RodentComponent setScore={setScore11} level={level} speed={speed}/>
                    </div>}
                </div>
                <div className="flex-container">
                    <div className="flex-item">
                        <RodentComponent setScore={setScore7} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore8} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore9} level={level} speed={speed}/>
                    </div>
                    {gridSize===4 && <div className="flex-item">
                        <RodentComponent setScore={setScore12} level={level} speed={speed}/>
                    </div>}
                </div>  
                {gridSize===4 &&
                <div className="flex-container">
                    <div className="flex-item">
                        <RodentComponent setScore={setScore13} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore14} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore15} level={level} speed={speed}/>
                    </div>
                    <div className="flex-item">
                        <RodentComponent setScore={setScore16} level={level} speed={speed}/>
                    </div>
                </div>
                }             
            </div>}
            {gameState === 'youwin' && <div className='parent-container' style={{backgroundColor:'green'}}><h1 style={{color:'white'}}>You Win!</h1></div>}
            {gameState === 'rodentwin' && <div className='parent-container' style={{backgroundColor:'green'}}><h1 style={{color:'white'}}>Time to call <br/>the exterminator!</h1></div>}
            {gameState === 'allgameswon' && <div className='parent-container' style={{backgroundColor:'green'}}><h1 style={{color:'white'}}>Wow. <br/>You are the exterminator.</h1></div>}
            
        </div>    
    );
}

export default GameComponent;
